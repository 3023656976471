<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text :disabled="!value" v-bind="attrs" v-on="on" @click="copy">
        CSV
      </v-btn>
    </template>
    <span>in Zwischenablage kopieren</span>
  </v-tooltip>
</template>
<script>
import { defineComponent } from "vue";
import { personName } from "common/utils/people.js";
import { today, formatDate } from "common/utils/date.js";

export default defineComponent({
  props: ["title", "subtitle", "headers", "items"],
  computed: {
    value() {
      if (!this.headers || !this.items) {
        return false;
      }
      let result = "";
      const fields = this.headers.map((el) => (el.csv ? el.csv : el)).flat();

      if (this.title) {
        result += this.parseItem(this.title);
        result +=
          fields.map(() => "").join(",") + this.formatDate(this.today()) + "\n";

        if (this.subtitle) {
          result += this.parseItem(this.subtitle);
        }
        result +=
          fields.map(() => "").join(",") + this.$_profilePerson.code + "\n\n";
      }

      // result += fields.map(() => "").join(",") + "\n";
      result += fields.map((el) => el.text).join(",") + "\n";
      for (const item of this.items) {
        result +=
          fields
            .map((el) => el.value)
            .map((el) => this.parseItem(item[el]))
            .join(",") + "\n";
      }

      return result;
    },
  },
  methods: {
    today,
    formatDate,
    async copy() {
      try {
        await navigator.clipboard.writeText(this.value);

        this.$root.showSuccess(
          "<strong>CSV wurde in in Zwischenablage kopiert</strong>"
        );
      } catch (err) {
        this.$root.showError("Fehler beim Kopieren in die Zwischenablage");
      }
    },
    parseItem(item) {
      if (!item) {
        return "";
      }

      // number
      if (typeof item === "number") {
        return item.toFixed(2);
      }
      let result = "";
      // object
      if (typeof item === "object") {
        // person
        if (item.firstName) {
          result = personName(item);
        } else if (item.description) {
          result = item.description;
        } else {
          result = item.code;
        }
      } else {
        result = item;
      }

      return this.wrapQuotes(result);
    },
    wrapQuotes(text) {
      if (text) {
        return '"' + text.replace(/"/g, '""') + '"';
      }
    },
  },
});
</script>
